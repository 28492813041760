<template>
  <div class="index_wrap">
    <h1 class="h_none">商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div style="height: 90px"></div>
    <div class="title">
      <span>VIP订购服务</span>
      <span></span>
    </div>
    <div class="describe">
      基础版、标准版、专业版等多种模式，满足不同用户需求，更专业、更快速、更智能
    </div>
    <div  class="tab">
      <span @click="active = true"  :class="{'active': active}">VIP</span>
      <span @click="active = false" :class="{'active': !active}">免费试用</span>
    </div>

    <!--    商标-->
    <div v-if="active" class="trademark">
      <span class="title_name">商标</span>
      <ul class="trademark_content">
        <li @click="tab(1)" :class="['trademark_li',{'active': indexs == 1}]">
          <img class="grade" src="../../assets/images/services/bo.png" alt="">
          <span class="titles">{{trademarkProductServiceManages[0].productName}}</span>
          <span class="line_"></span>
          <p class="price"><span>{{trademarkProductServiceManages[0].productPrice}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>查询次数<i style="font-size: 16px; color: red;">10w次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>驳通智写<i style="font-size: 16px; color: red;">100次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>仅限中国</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>图形+文字</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标公告</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标对比</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>法律文书</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>业务线索</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tab(2)" :class="['trademark_li',{'active': indexs == 2}]">
          <img class="grade" src="../../assets/images/services/bai.png" alt="">
          <span class="titles">{{trademarkProductServiceManages[1].productName}}</span>
          <span class="line_"></span>
          <p class="price"><span>{{trademarkProductServiceManages[1].productPrice}}</span>元/100次/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>查询次数<i style="font-size: 16px;color: red">100次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>驳通智写<i style="font-size: 16px; color: red;">5次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>不限国家</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>图形+文字</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标公告</span>
            </li>
            <li >
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标对比</span>
            </li>
            <li class="err">
              <img src="../../assets/images/services/error.png" alt="">
              <span>商标监控</span>
            </li>
            <li class="err">
              <img src="../../assets/images/services/error.png" alt="">
              <span>法律文书</span>
            </li>
            <li class="err">
              <img src="../../assets/images/services/error.png" alt="">
              <span>业务线索</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tab(3)" :class="['trademark_li',{'active': indexs == 3}]">
          <div class="recommend">推荐:80%用户购买</div>
          <img class="grade" src="../../assets/images/services/huang.png" alt="">
          <span class="titles">{{trademarkProductServiceManages[2].productName}}</span>
          <span class="line_"></span>
          <p class="price"><span>{{trademarkProductServiceManages[2].productPrice}}</span>元/2000次/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>查询次数 <i style="color: red;font-size: 16px">2000次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>驳通智写<i style="font-size: 16px; color: red;">20次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>不限国家</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>图形+文字</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标公告</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标对比</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标监控</span>
            </li>
            <li class="err">
              <img src="../../assets/images/services/error.png" alt="">
              <span>法律文书</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>业务线索</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tab(4)" :class="['trademark_li',{'active': indexs == 4}]">
          <img class="grade" src="../../assets/images/services/bo.png" alt="">
          <span class="titles">{{trademarkProductServiceManages[3].productName}}</span>
          <span class="line_"></span>
          <p class="price"><span>{{trademarkProductServiceManages[3].productPrice}}</span>元/15000次/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>查询次数<i style="font-size: 16px;color: red">15000次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>驳通智写<i style="font-size: 16px; color: red;">100次</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>不限国家</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>图形+文字</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标公告</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标对比</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>法律文书</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>业务线索</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
      </ul>
    </div>

    <!--    专利-->
    <div v-if="active" class="patent">
      <span class="title_name">专利</span>
      <ul class="trademark_content">
        <li @click="tabs(1)" :class="['trademark_li',{'active': indexss == 1}]">
          <img class="grade" src="../../assets/images/services/bai.png" alt="">
          <span class="titles">{{patentProductServiceManages[0].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice">原价<span>{{patentProductServiceManages[0].productPrice}}元</span></p>
          <p class="price"><span>{{patentProductServiceManages[0].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>翻译字数<i style="color: red;font-size: 16px">5w</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>不限翻译方向</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持文本翻译</span>
            </li>
            <li class="err">
              <img src="../../assets/images/services/error.png" alt="">
              <span>支持word文件翻译</span>
            </li>
<!--            <li class="err">-->
<!--              <img src="../../assets/images/services/error.png" alt="">-->
<!--              <span>支持Excel文件翻译</span>-->
<!--            </li>-->
            <li class="err">
              <img src="../../assets/images/services/error.png" alt="">
              <span>支持PDF文件翻译</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tabs(2)" :class="['trademark_li',{'active': indexss == 2}]">
          <img class="grade" src="../../assets/images/services/huang.png" alt="">
          <span class="titles">{{patentProductServiceManages[1].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice">原价<span>{{patentProductServiceManages[1].productPrice}}元</span></p>
          <p class="price"><span>{{patentProductServiceManages[1].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>翻译字数<i style="color: red;font-size: 16px">10w</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>不限翻译方向</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持文本翻译</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持word文件翻译</span>
            </li>
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>支持Excel文件翻译</span>-->
<!--            </li>-->
            <li class="err">
              <img src="../../assets/images/services/error.png" alt="">
              <span>支持PDF文件翻译</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tabs(3)" :class="['trademark_li',{'active': indexss == 3}]">
          <img class="grade" src="../../assets/images/services/bo.png" alt="">
          <span class="titles">{{patentProductServiceManages[2].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice">原价<span>{{patentProductServiceManages[2].productPrice}}元</span></p>
          <p class="price"><span>{{patentProductServiceManages[2].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>翻译字数<i style="color: red;font-size: 16px">50w</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>不限翻译方向</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持文本翻译</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持word文件翻译</span>
            </li>
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>支持Excel文件翻译</span>-->
<!--            </li>-->
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持PDF文件翻译</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tabs(4)" :class="['trademark_li',{'active': indexss == 4}]">
          <img class="grade" src="../../assets/images/services/hei.png" alt="">
          <span class="titles">{{patentProductServiceManages[3].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice">原价<span>{{patentProductServiceManages[3].productPrice}}元</span></p>
          <p class="price"><span>{{patentProductServiceManages[3].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>翻译字数<i style="color: red;font-size: 16px">100w</i></span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>不限翻译方向</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持文本翻译</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持word文件翻译</span>
            </li>
<!--            <li>-->
<!--              <img src="../../assets/images/services/hook.png" alt="">-->
<!--              <span>支持Excel文件翻译</span>-->
<!--            </li>-->
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>支持PDF文件翻译</span>
            </li>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
      </ul>
    </div>

    <!--    托管-->
    <div v-if="active" class="custody">
      <span class="title_name">托管平台</span>
      <ul class="trademark_content">
        <li @click="tabsss(1)" :class="['trademark_li',{'active': indexssss == 1}]">
          <img class="grade" src="../../assets/images/services/bai.png" alt="">
          <span class="titles">{{custodyProductServiceManages[0].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice"><span></span></p>
          <p class="price"><span>{{custodyProductServiceManages[0].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>托管时间：一年</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标/专利托管量：<i style="color: red;font-size: 16px">10</i>条</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>软著/资质托管量：不限</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>托管申请人：<i style="color: red;font-size: 16px">1</i>个</span>
            </li>

          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tabsss(2)" :class="['trademark_li',{'active': indexssss == 2}]">
          <img class="grade" src="../../assets/images/services/huang.png" alt="">
          <span class="titles">{{custodyProductServiceManages[1].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice"><span></span></p>
          <p class="price"><span>{{custodyProductServiceManages[1].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <ul class="content">
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>托管时间：一年</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>商标/专利托管量：<i style="color: red;font-size: 16px">20</i>条</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>软著/资质托管量：不限</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>托管申请人：<i style="color: red;font-size: 16px">1</i>个</span>
              </li>

            </ul>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tabsss(3)" :class="['trademark_li',{'active': indexssss == 3}]">
          <img class="grade" src="../../assets/images/services/bo.png" alt="">
          <span class="titles">{{custodyProductServiceManages[2].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice"><span></span></p>
          <p class="price"><span>{{custodyProductServiceManages[2].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <ul class="content">
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>托管时间：一年</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>商标/专利托管量：<i style="color: red;font-size: 16px">50</i>条</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>软著/资质托管量：不限</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>托管申请人：<i style="color: red;font-size: 16px">3</i>个</span>
              </li>

            </ul>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tabsss(4)" :class="['trademark_li',{'active': indexssss == 4}]">
          <img class="grade" src="../../assets/images/services/hei.png" alt="">
          <span class="titles">{{custodyProductServiceManages[3].productName}}</span>
          <span class="line_"></span>
          <p class="originalPrice"><span></span></p>
          <p class="price"><span>{{custodyProductServiceManages[3].price}}</span>元/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <ul class="content">
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>托管时间：一年</span>
              </li>
              <li style="white-space: nowrap">
                <img src="../../assets/images/services/hook.png" alt="">
                <span>商标/专利托管量：<i style="color: red;font-size: 16px;white-space: nowrap">200</i>条</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>软著/资质托管量：不限</span>
              </li>
              <li>
                <img src="../../assets/images/services/hook.png" alt="">
                <span>托管申请人：<i style="color: red;font-size: 16px">5</i>个</span>
              </li>

            </ul>
          </ul>

          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
      </ul>
    </div>

    <!--    监控-->
    <div v-if="active" class="monitor">
      <span class="title_name">扩展</span>
      <ul class="trademark_content">
        <li @click="tabss(1)" :class="['trademark_li',{'active': indexsss == 1}]">
          <img class="grade" src="../../assets/images/services/bai.png" alt="">
          <span class="titles">{{extendProductServiceManages[0].productName}}</span>
          <span class="line_"></span>
<!--          <p class="originalPrice">原价<span>{{extendProductServiceManages[0].productPrice}}元</span></p>-->
          <p class="price"><span>{{extendProductServiceManages[0].price}}</span>元/10个/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li style="align-items: center;padding: 0 33px 15px 29px">
              <div class="monitor_item">
                监控个数<i>{{num}}</i>
              </div>
              <div @click.stop>
                <el-input-number style="width: 95px" v-model="num" :step="10" :min="10" size="mini" ></el-input-number>
              </div>

            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>文字商标监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>图形商标监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>近似商标监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>全球43国监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>全类别监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>竞争对手监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>报告自动发送</span>
            </li>
          </ul>
          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
        <li @click="tabss(2)" :class="['trademark_li',{'active': indexsss == 2}]">
          <img class="grade" src="../../assets/images/services/bai.png" alt="">
          <span class="titles">{{extendProductServiceManages[1].productName}}</span>
          <span class="line_"></span>
          <!--          <p class="originalPrice">原价<span>{{extendProductServiceManages[0].productPrice}}元</span></p>-->
          <p class="price"><span>{{extendProductServiceManages[1].price}}</span>元/10次/年</p>
          <p class="vip"><span class="line"></span> <i>VIP权益</i> <span class="line"></span></p>
          <ul class="content">
            <li style="align-items: center;padding: 0 28px 15px 23px">
              <div class="monitor_item">
                服务包数量<i>{{nums}}</i>
              </div>
              <div @click.stop>
                <el-input-number style="width: 95px" v-model="nums" :step="10" :min="10" size="mini" ></el-input-number>
              </div>

            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标信息对比</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标图样对比</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>整体外观分析</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>含义发音分析</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商品服务分析</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商品用户分析</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商品市场分析</span>
            </li>
          </ul>
          <div class="bottomright">
            <img src="../../assets/images/services/selected.png" alt="">
          </div>
        </li>
      </ul>
    </div>

    <!--    试用-->
    <div v-if="!active" class="free">
      <ul class="trademark_content">
        <li  :class="['trademark_li',{'active': indexss == 5}]">
          <div style="display: flex;justify-content: center" class="">
            <img style="width: 20px;height: 20px;margin-top: 26px;margin-left: 2px" src="../../assets/images/services/yuan.png" alt="">
            <span style="margin-left: 3px" class="titles">{{trialProductServiceManages[0].productName}}</span>
          </div>
          <span class="line_"></span>
<!--          <p class="originalPrice">原价<span>500元</span></p>-->
<!--          <p class="price"><span>{{trademarkProductServiceManages[0].productPrice}}</span>元/年</p>-->
          <p class="vip"><span class="line"></span> <i>试用权益</i> <span class="line"></span></p>
          <ul class="content">
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>查询50次</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>驳通智写5次</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>7天免费使用</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>图形+文字</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标公告</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标对比</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>商标监控</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>法律文书</span>
            </li>
            <li>
              <img src="../../assets/images/services/hook.png" alt="">
              <span>业务线索</span>
            </li>
          </ul>
          <el-button type="primary" size="mini" :disabled="!isDisable || (isVip != 'COMMON')" class="freeBtn" @click="applicaEvent()">
            申请试用
          </el-button>
<!--          <div class="bottomright">-->
<!--            <img src="../../assets/images/services/selected.png" alt="">-->
<!--          </div>-->
        </li>
      </ul>
    </div>

    <div v-if="active && (indexs || indexss || indexsss || indexssss)" class="pay">
      <div class="pay_cen">
          <div class="zhu">
            <img src="../../assets/images/services/zhu.png" alt="">
            <span>注:</span>
          </div>
          <div class="explain">
            <p>1、多国商标查询时，每个国家消耗一次</p>
            <p>2、同时购买专利和商标产品，可享<span>9折</span>优惠（监控产品除外）</p>
          </div>
          <div v-if="indexs && indexss" class="payPrice">
            <p>
              <span>原价</span>
              <span>{{this.price}}元</span>
            </p>
            <p>
              <span>折后价</span>
              <span>{{this.prices}}元</span>
            </p>
          </div>
        <div v-else class="payPrice">
          <p style="visibility: hidden">
            <span>原价</span>
            <span>{{this.price}}元</span>
          </p>
          <p>
            <span>价格</span>
            <span>{{this.prices}}元</span>
          </p>
        </div>
          <div @click="toPay" class="toPay">
            去支付
          </div>
      </div>
    </div>
    <footers></footers>
    <el-dialog :visible.sync="dialogVisibles" :close-on-click-modal="false" custom-class="account_dialog"
               :before-close="closeEvent">
      <info @dialog="getDialogs" :dialogVisibles="dialogVisibles" :server-type="0" :dialog-close="dialogCloses"
            @dialogCloseBool="dialogCloseBools"></info>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import info from "@/components/account/info";
  import login from "@/components/common/login"
  import { mapState, mapMutations, mapGetters } from 'vuex'

  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能,知识产权,蓝灯鱼招聘,蓝灯鱼地址,关于蓝灯鱼'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是提供商标查询，专利检索，专利翻译服务的知识产权智能检索平台，应用人工智能技术，已发布商标图形查询，专利翻译，CPC官文提取工具，知识产权管理系统等系列产品。'
      }],
    },
    data () {
      return {
        flag: true,
        isDisable: true,
        num: 10,
        nums: 10,
        saveOrderId: "",
        saveProductServiceId: "",
        saveOrderCurrency: "",
        //是否申请过
        existApply:false,
        dialogClose: false,
        dialogCloses: false,
        dialogVisibles: false,
        dialogVisible: false,
        price:'',
        prices:"",
        active: true,
        isCN: true,
        indexs: 0,
        indexss: 0,
        indexsss: 0,
        indexssss: 0,
        trademarkProductServiceManages: [
          {
            productName:'',
            productPrice:''
          },
          {
            productName:'',
            productPrice:''
          },
          {
            productName:'',
            productPrice:''
          },
          {
            productName:'',
            productPrice:''
          }
        ],
        patentProductServiceManages: [],
        custodyProductServiceManages:[],
        trialProductServiceManages: [],
        extendProductServiceManages:[],
        isVip: "COMMON"
      }
    },
    computed: {
      ...mapState(["me", "noticeList", "headerBgBool"]),
    },
    async mounted() {
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
      await this.getList()
      await this.freeTimes()
      await this.isVips()

    },
    components: {
      footers,
      info,
      login
    },
    methods: {
      getVipType(){
        let obj = null
        switch (this.me.vipType) {
          case "VIP_A": obj = {
            num: 1,
            text: '白银卡'
          };break;
          case "VIP_B": obj = {
            num: 2,
            text: '黄金卡'
          };break;
          case "VIP_C": obj = {
            num: 3,
            text: '铂金卡'
          };break;
          case "VIP_D": obj = {
            num: 4,
            text: '黑钻卡'
          };break;
        }
          return obj;
      },
      //是否商标vip
      isVips(){
        this.$axios
          .get("/product/servicemanage/getTmVipLevel").then(({data}) => {
          if(data.code == 0){
            this.isVip = data.data
          }
        })
      },
      //试用服务次数查询
      freeTimes(){
        this.$axios
          .get("/product/servicemanage/getTrialCount").then(({data}) => {
          if(data.code == 0){
            this.isDisable = data.data.totalTrialCount - data.data.trialCount
          }
        })
      },
      // 生成订单
      async saveOrderAjax () {
        let data = await this.$axios.post("/order/order/saveLanternfishOrder", {
          productServiceId: this.saveProductServiceId,
          productId: this.saveOrderId,
          currency: this.saveOrderCurrency, //币种：CNY人民币,USD美元,EUR欧元,JPY日元
          source: "web",
          orderType: "lantern" //订单类型： 蓝灯鱼订单为 lantern  普通订单为common
        });
        if (data.data.code == 0) {
          this.$router.push({
            path: "/pay/paySuccess",
            query: {
              payType: 2,
              td:  -11  ,
              try: 'try'
            }
          });
        }
      },
      getDialogs (param) {
        this.dialogVisibles = param;
        this.saveOrderAjax();
      },
      // 申请
      applicaEvent () {
        if (this.tool.getCookie("auth")) {
          let param = this.trialProductServiceManages[0]
          this.saveOrderId = param.productId;
          this.saveProductServiceId = param.id;
          this.saveOrderCurrency = param.currency;
          //是否申请过
          this.existApply = param.existApply
          //自动开启服务的标识符
          // this.translateId = param.translateId
          //   let data = await this.$axios.get(`/order/order/existLanternfishApplyOrder/${param.id}`);
          //   if (data.data.code == 0) {
          //     if (data.data.data) {
          //       param.alreadyAppliedBool = true;
          //     } else {
          //       param.alreadyAppliedBool = false;
          this.saveOrderAjax();
        } else {
          this.dialogVisible = true
        }
      },
      // 登录弹层隐藏
      loginDialog(data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      closeEvent(done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool(data) {
        this.dialogClose = data;
      },
      closeEvents(done) {
        this.dialogCloses = true;
        done();
      },
      dialogCloseBools(data) {
        this.dialogCloses = data;
      },
      //去支付
      toPay(){
        if (this.tool.getCookie("auth")) {
        } else {
          this.dialogVisible = true
          return;
        }
        //
        // let data = {}
        // if(this.indexs && !this.indexss){
        //   data = this.trademarkProductServiceManages[this.indexs - 1]
        //   this.prices = this.trademarkProductServiceManages[this.indexs - 1].price
        //   if(this.indexsss){
        //     this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
        //   }
        // }else if(!this.indexs && this.indexss){
        //   data = this.patentProductServiceManages[this.indexss - 1]
        //   this.prices = this.patentProductServiceManages[this.indexss - 1].price
        //   if(this.indexsss){
        //     this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
        //   }
        // }else if(this.indexs && this.indexss){
        //   // data = this.patentProductServiceManages[this.indexss - 1]
        //   this.price = this.trademarkProductServiceManages[this.indexs - 1].price + this.patentProductServiceManages[this.indexss - 1].price
        //   this.prices = this.price*0.9
        //   if(this.indexsss){
        //     this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
        //   }
        // }
        this.$router.push({
          path:'/pay/orderInfo',
          query:{
            id: [this.extendProductServiceManages[this.indexsss - 1] ? this.extendProductServiceManages[this.indexsss - 1].id : '', this.trademarkProductServiceManages[this.indexs - 1] ? this.trademarkProductServiceManages[this.indexs - 1].id : '' , this.patentProductServiceManages[this.indexss - 1] ? this.patentProductServiceManages[this.indexss - 1].id : '', this.custodyProductServiceManages[this.indexssss - 1] ? this.custodyProductServiceManages[this.indexssss - 1].id : ''].filter(item => item).join(';'),
            modeType: '',
            tariff: this.prices,
            name: '',
            discountFlag: 0,
            monitorFlag: this.indexsss == 1 ? this.num : this.indexsss == 2 ? this.nums : undefined,
            flag: this.$route.query.flag && this.flag ? this.$route.query.flag : undefined
          }
        })

      },
      //获取订购购买服务列表接口
      getList(){
        this.$axios.get('/product/productservicemanage/getPrePublishServiceList').then(({data}) => {
          if(data.code == 0){
            this.trademarkProductServiceManages = data.data.trademarkProductServiceManages.sort(function (a, b) {
              return (a.id - b.id);
            })
            this.patentProductServiceManages =data.data.patentProductServiceManages.sort(function (a, b) {
              return (a.id - b.id);
            })
            this.trialProductServiceManages = data.data.trialProductServiceManages.sort(function (a, b) {
              return (a.id - b.id);
            })
            this.extendProductServiceManages = data.data.extendProductServiceManages.sort(function (a, b) {
              return (a.id - b.id);
            }).sort(function (a, b) {
              return (a.id - b.id);
            })
            this.custodyProductServiceManages = data.data.custodyProductServiceManages.sort(function (a, b) {
              return (a.id - b.id);
            })
            if(this.$route.query.flag){
              this.num = this.$route.query.value
              this.tabss(1)
              this.$router.push({
                path:'/pay/orderInfo',
                query:{
                  id: this.$route.query.prodServiceId,
                  modeType: '',
                  tariff: this.$route.query.prices1,
                  name: '',
                  discountFlag: 0,
                  monitorFlag: this.indexsss  ? this.num : undefined,
                  flag: this.$route.query.flag && this.flag ? this.$route.query.flag : undefined
                }
              })
            }
          }
        }).catch(err => {

        })
      },
      jump(){
        this.$router.push({
          path:'/recruitment'
        })
      },
      //
      jisuan(){
        let data = ''
        if(this.indexs && !this.indexss){
          data = this.trademarkProductServiceManages[this.indexs - 1]
          this.prices = this.trademarkProductServiceManages[this.indexs - 1].price
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
          if(this.indexssss){
            //custodyProductServiceManages
            this.prices += this.custodyProductServiceManages[this.indexssss - 1].price
          }
        }else if(!this.indexs && this.indexss){
          data = this.patentProductServiceManages[this.indexss - 1]
          this.prices = this.patentProductServiceManages[this.indexss - 1].price
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
          if(this.indexssss){
            //custodyProductServiceManages
            this.prices += this.custodyProductServiceManages[this.indexssss - 1].price
          }
        }else if(this.indexs && this.indexss){
          // data = this.patentProductServiceManages[this.indexss - 1]
          this.price = Number(this.trademarkProductServiceManages[this.indexs - 1].price) + Number(this.patentProductServiceManages[this.indexss - 1].price)
          this.prices = this.price*0.9
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
          if(this.indexssss){
            //custodyProductServiceManages
            this.prices += this.custodyProductServiceManages[this.indexssss - 1].price
          }
        }else if(!this.indexs && !this.indexss && !this.indexssss && this.indexsss){
          if(this.indexsss == 1){
            this.prices = this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices = this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
        }else if(!this.indexs && !this.indexss && this.indexssss){
          this.prices = this.custodyProductServiceManages[this.indexssss - 1].price
          if(this.indexsss == 1){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.num/10)
          }
          if(this.indexsss == 2){
            this.prices += this.extendProductServiceManages[this.indexsss - 1].price*(this.nums/10)
          }
        }
      },
      tab(index){
        if(this.indexs == index){
          this.indexs = 0
          this.jisuan()
          return;
        }
        this.indexs = index
        this.jisuan()
      },
      tabs(index){
        if(this.indexss == index){
          this.indexss = 0
          this.jisuan()
          return;
        }
        this.indexss = index
        this.jisuan()
      },
      tabss(index){
        if(this.indexsss == index){
          this.flag = false
          this.indexsss = 0
          this.jisuan()
          return;
        }
        this.indexsss = index
        this.flag = true
        this.jisuan()
      },
      tabsss(index){
        if(this.indexssss == index){
          this.flag = false
          this.indexssss = 0
          this.jisuan()
          return;
        }
        this.indexssss = index
        this.flag = true
        this.jisuan()
      },
    },
    watch:{
      indexssss(newVal){
        if(this.getVipType() && newVal != 0){
          if(newVal < this.getVipType().num){
            this.indexssss = 0
            this.$message({
              showClose: true,
              message: `您现在是${this.getVipType().text}，购买会员等级不得低于当前会员等级`,
              type: 'error'
            });
          }
        }
      },
      num(newValue,oldValue){
        this.jisuan()
      },
      nums(newValue,oldValue){
        this.jisuan()
      }
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    .title{
      margin: 47px auto 0;
      display: block;
      width: 156px;
      height: 37px;
      position: relative;
      span:nth-of-type(1){
        position: absolute;
        z-index: 1;
        font-size: 26px;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
      }
      span:nth-of-type(2){
        display: block;
        width: 155px;
        height: 14px;
        background: linear-gradient(270deg, #F1F3FA 0%, #E9C570 100%);
        opacity: 0.84;
        position: absolute;
        bottom: 0;
      }
    }
    .describe{
      width: 784px;
      height: 28px;
      margin: 19px auto 0;
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      letter-spacing: 1px;
    }
    .trademark{
      margin: 0 auto;
      width: 1200px;
      height: 560px;
      background: linear-gradient( 351deg, #F3F6FF 0%, #E4EFFF 100%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 0 0 29px;
      .title_name{
        font-size: 26px;
        font-weight: 500;
        color: #2E4288;
        letter-spacing: 1px;
        margin-right: 30px;
      }
      .trademark_content{
        display: flex;
        .trademark_li{
          cursor: pointer;
          width: 235px;
          height: 470px;
          overflow: hidden;
          background: #FFFFFF;
          position: relative;
          margin-right: 40px;
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
          border-radius: 8px;
          text-align: center;
          .recommend{
            width: 128px;
            height: 24px;
            background: #F7EFDD;
            border-radius: 8px 5px 5px 0px;
            font-size: 12px;
            font-weight: 400;
            color: #B68416;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
          }
          .grade{
            position: absolute;
            top: 11px;
            right: 15px;
          }
          .titles{
            font-size: 18px;
            font-weight: 500;
            display: inline-block;
            color: #000000;
            font-weight: 500;
            margin: 32px 0 0px 0;
          }
          .line_{
            width: 202px;
            height: 1px;
            display: inline-block;
            background: rgba(46,66,136,0.48);
          }
          .price{
            font-size: 12px;
            font-weight: 400;
            color: #989898;
            margin-top: 9px;
            margin-left: 27px;
            span{
              font-size: 26px;
              font-weight: 600;
              color: #FE5000;
              line-height: 37px;
              letter-spacing: 1px;
              margin-right: 2px;
            }
          }
          .vip{
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            height: 17px;
            display: inline-flex;
            align-items: center;
            margin-bottom: 16px;
            .line{
              width: 41px;
              height: 1px;
              display: inline-block;
              background:  #EBE9E9;
            }
            i{
              margin: 0 9px;
            }
          }
          .content{
            li{
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 33px 11px 34px;
              img{
                width: 14px;
                height: 10px;
              }
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              &.err {
                img{
                  width: 12px;
                  height: 12px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #C3C2C2;
              }
            }
          }
          .bottomright{
            display: none;
          }
        }
        .trademark_li.active {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
        .trademark_li:hover {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
      }
    }
    .custody{
      margin: 38px auto 0;
      width: 1200px;
      height: 470px;
      background: linear-gradient( 351deg, #F3F6FF 0%, #E4EFFF 100%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 0 0 29px;
      .title_name{
        font-weight: 500;
        font-size: 26px;
        color: #2E4288;
        letter-spacing: 1px;
        margin-right: 30px;
      }
      .trademark_content{
        display: flex;
        .trademark_li{
          cursor: pointer;
          width: 235px;
          height: 396px;
          overflow: hidden;
          background: #FFFFFF;
          position: relative;
          margin-right: 40px;
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
          border-radius: 8px;
          text-align: center;
          .recommend{
            width: 128px;
            height: 24px;
            background: #F7EFDD;
            border-radius: 8px 5px 5px 0px;
            font-size: 12px;
            font-weight: 400;
            color: #B68416;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
          }
          .grade{
            position: absolute;
            top: 11px;
            right: 15px;
          }
          .titles{
            font-size: 18px;
            font-weight: 500;
            display: inline-block;
            color: #000000;
            font-weight: 500;
            margin: 32px 0 0px 0;
          }
          .line_{
            width: 202px;
            height: 1px;
            display: inline-block;
            background: rgba(46,66,136,0.48);
          }
          .originalPrice{
            font-size: 14px;
            font-weight: 400;
            color: #B98269;
            margin-left: -95px;
            margin-top: 4px;
            span{
              text-decoration: line-through;
              margin-left: 2px;
            }
          }
          .price{
            font-size: 12px;
            font-weight: 400;
            color: #989898;
            margin-left: 27px;
            span{
              font-size: 26px;
              font-weight: 600;
              color: #FE5000;
              line-height: 37px;
              letter-spacing: 1px;
              margin-right: 2px;
            }
          }
          .vip{
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            height: 17px;
            display: inline-flex;
            align-items: center;
            margin-bottom: 16px;
            .line{
              width: 41px;
              height: 1px;
              display: inline-block;
              background:  #EBE9E9;
            }
            i{
              margin: 0 9px;
            }
          }
          .content{
            li{
              display: flex;
              justify-content: space-between;
              padding: 0 33px 15px 34px;
              align-items: center;
              img{
                width: 14px;
                height: 10px;
              }
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              &.err {
                img{
                  width: 12px;
                  height: 12px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #C3C2C2;
              }
            }
          }
          .bottomright{
            display: none;
          }
        }
        .trademark_li.active {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
        .trademark_li:hover {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
      }
    }
    .patent{
      margin: 38px auto 0;
      width: 1200px;
      height: 470px;
      background: linear-gradient( 346deg, #F3F6FF 0%, #DCDCF7 100%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 0 0 29px;
      .title_name{
        font-size: 26px;
        font-weight: 500;
        color: #2E4288;
        letter-spacing: 1px;
        margin-right: 30px;
      }
      .trademark_content{
        display: flex;
        .trademark_li{
          cursor: pointer;
          width: 235px;
          height: 396px;
          overflow: hidden;
          background: #FFFFFF;
          position: relative;
          margin-right: 40px;
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
          border-radius: 8px;
          text-align: center;
          .recommend{
            width: 128px;
            height: 24px;
            background: #F7EFDD;
            border-radius: 8px 5px 5px 0px;
            font-size: 12px;
            font-weight: 400;
            color: #B68416;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
          }
          .grade{
            position: absolute;
            top: 11px;
            right: 15px;
          }
          .titles{
            font-size: 18px;
            font-weight: 500;
            display: inline-block;
            color: #000000;
            font-weight: 500;
            margin: 32px 0 0px 0;
          }
          .line_{
            width: 202px;
            height: 1px;
            display: inline-block;
            background: rgba(46,66,136,0.48);
          }
          .originalPrice{
            font-size: 14px;
            font-weight: 400;
            color: #B98269;
            margin-left: -95px;
            margin-top: 4px;
            span{
              text-decoration: line-through;
              margin-left: 2px;
            }
          }
          .price{
            font-size: 12px;
            font-weight: 400;
            color: #989898;
            margin-left: 27px;
            span{
              font-size: 26px;
              font-weight: 600;
              color: #FE5000;
              line-height: 37px;
              letter-spacing: 1px;
              margin-right: 2px;
            }
          }
          .vip{
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            height: 17px;
            display: inline-flex;
            align-items: center;
            margin-bottom: 16px;
            .line{
              width: 41px;
              height: 1px;
              display: inline-block;
              background:  #EBE9E9;
            }
            i{
              margin: 0 9px;
            }
          }
          .content{
            li{
              display: flex;
              justify-content: space-between;
              padding: 0 33px 15px 34px;
              align-items: center;
              img{
                width: 14px;
                height: 10px;
              }
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              &.err {
                img{
                  width: 12px;
                  height: 12px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #C3C2C2;
              }
            }
          }
          .bottomright{
            display: none;
          }
        }
        .trademark_li.active {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
        .trademark_li:hover {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
      }
    }
    .monitor{
      margin: 38px auto 0;
      width: 1200px;
      height: 530px;
      background: linear-gradient( 346deg, #F3F6FF 0%, #DCDCF7 100%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 0 0 29px;
      .monitor_item{
        font-size: 12px;
        i{
          color: red;
          font-size: 16px;
        }
      }
      .title_name{
        font-size: 26px;
        font-weight: 500;
        color: #2E4288;
        letter-spacing: 1px;
        margin-right: 30px;
      }
      .trademark_content{
        display: flex;
        .trademark_li{
          cursor: pointer;
          width: 235px;
          height: 455px;
          overflow: hidden;
          background: #FFFFFF;
          position: relative;
          margin-right: 40px;
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
          border-radius: 8px;
          text-align: center;
          .recommend{
            width: 128px;
            height: 24px;
            background: #F7EFDD;
            border-radius: 8px 5px 5px 0px;
            font-size: 12px;
            font-weight: 400;
            color: #B68416;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
          }
          .grade{
            position: absolute;
            top: 11px;
            right: 15px;
          }
          .titles{
            font-size: 18px;
            font-weight: 500;
            display: inline-block;
            color: #000000;
            font-weight: 500;
            margin: 32px 0 0px 0;
          }
          .line_{
            width: 202px;
            height: 1px;
            display: inline-block;
            background: rgba(46,66,136,0.48);
          }
          .originalPrice{
            font-size: 14px;
            font-weight: 400;
            color: #B98269;
            margin-left: -95px;
            margin-top: 4px;
            span{
              text-decoration: line-through;
              margin-left: 2px;
            }
          }
          .price{
            font-size: 12px;
            font-weight: 400;
            color: #989898;
            margin-left: 27px;
            span{
              font-size: 26px;
              font-weight: 600;
              color: #FE5000;
              line-height: 37px;
              letter-spacing: 1px;
              margin-right: 2px;
            }
          }
          .vip{
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            height: 17px;
            display: inline-flex;
            align-items: center;
            margin-bottom: 16px;
            .line{
              width: 41px;
              height: 1px;
              display: inline-block;
              background:  #EBE9E9;
            }
            i{
              margin: 0 9px;
            }
          }
          .content{
            li{
              display: flex;
              justify-content: space-between;
              padding: 0 33px 15px 34px;
              align-items: center;
              img{
                width: 14px;
                height: 10px;
              }
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              &.err {
                img{
                  width: 12px;
                  height: 12px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #C3C2C2;
              }
            }
          }
          .bottomright{
            display: none;
          }
        }
        .trademark_li.active {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
        .trademark_li:hover {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
      }
    }
    .free{
      margin: 38px auto 0;
      width: 1200px;
      height: 470px;
      background: linear-gradient(351deg, #F3F6FF 0%, #E4EFFF 100%);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 29px;
      .title_name{
      font-size: 26px;
        font-weight: 500;
        color: #2E4288;
        letter-spacing: 1px;
        margin-right: 30px;
      }
      .trademark_content{
        display: flex;
        .trademark_li{
          cursor: pointer;
          width: 235px;
          height: 445px;
          overflow: hidden;
          background: #FFFFFF;
          position: relative;
          margin-right: 40px;
          box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1800);
          border-radius: 8px;
          text-align: center;
          .recommend{
            width: 128px;
            height: 24px;
            background: #F7EFDD;
            border-radius: 8px 5px 5px 0px;
            font-size: 12px;
            font-weight: 400;
            color: #B68416;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
          }
          .grade{
            position: absolute;
            top: 11px;
            right: 15px;
          }
          .titles{
            font-size: 18px;
            font-weight: 500;
            display: inline-block;
            color: #000000;
            font-weight: 500;
            margin: 23px 0 0px 0;
          }
          .line_{
            width: 202px;
            height: 1px;
            display: inline-block;
            background: rgba(46,66,136,0.48);
          }
          .originalPrice{
            font-size: 14px;
            font-weight: 400;
            color: #B98269;
            margin-left: -95px;
            margin-top: 4px;
            span{
              text-decoration: line-through;
              margin-left: 2px;
            }
          }
          .price{
            font-size: 12px;
            font-weight: 400;
            color: #989898;
            margin-left: 27px;
            span{
              font-size: 26px;
              font-weight: 600;
              color: #FE5000;
              line-height: 37px;
              letter-spacing: 1px;
              margin-right: 2px;
            }
          }
          .vip{
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            height: 17px;
            display: inline-flex;
            align-items: center;
            margin-bottom: 19px;
            margin-top: 15px;
            .line{
              width: 41px;
              height: 1px;
              display: inline-block;
              background:  #EBE9E9;
            }
            i{
              margin: 0 9px;
            }
          }
          .content{
            li{
              display: flex;
              justify-content: space-between;
              padding: 0 33px 11px 34px;
              align-items: center;
              img{
                width: 14px;
                height: 10px;
              }
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              &.err {
                img{
                  width: 12px;
                  height: 12px;
                }
                font-size: 14px;
                font-weight: 400;
                color: #C3C2C2;
              }
            }
          }
          .bottomright{
            display: none;
          }
        }
        .trademark_li.active {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
        .trademark_li:hover {
          box-shadow: 0px 0px 4px 2px rgba(16,36,106,0.1800);
          border: 1px solid #2E54D9;
          .bottomright{
            display: block;
            position: absolute;
            bottom: -35px;
            right: -41px;
          }
        }
      }
      .freeBtn{
        &.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover {
          color: #fff!important;
          background-color: #a0cfff!important;
          border-color: #a0cfff!important;
        }
        /*color: #FFFFFF;*/
      }
    }
    .pay{
      z-index: 100;
      width: 100%;
      height: 225px;
      background: white;
      background: url("../../assets/images/services/back.png") no-repeat 100% 100%;
      position: sticky;
      bottom: 0;
      .pay_cen{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        padding: 54px 0 0 70px;
        .zhu{
          font-size: 14px;
          font-weight: 400;
          color: #9C644B;
          display: flex;
          align-items: center;
          height: 21px;
          img{
            width: 19px;
            height: 21px;
            margin-right: 11px;
          }
        }
        .explain{
          font-size: 14px;
          font-weight: 400;
          color: #555555;
          line-height: 20px;
          margin-left: 13px;
          margin-right: 220px;
          p{
            margin-bottom: 12px;
          }
          span{
            color: #FE5000;
            font-size: 18px;
          }
        }
        .payPrice{
          p:nth-of-type(1){
            span:nth-of-type(1){
              font-size: 20px;
              font-weight: 400;
              color: #B98269;
              margin-right: 13px;
            }
            span:nth-of-type(2){
              font-size: 22px;
              font-weight: 400;
              color: #B98269;
              line-height: 30px;
              text-decoration: line-through;
              letter-spacing: 1px;
            }
          }
          p:nth-of-type(2){
            span:nth-of-type(1){
              font-size: 20px;
              font-weight: 600;
              color: #FE5000;
              margin-right: 13px;
            }
            span:nth-of-type(2){
              font-size: 38px;
              font-weight: 600;
              color: #FE5000;
              line-height: 53px;
              letter-spacing: 1px;
            }
          }
        }
        .toPay{
          cursor: pointer;
          width: 126px;
          height: 40px;
          background: #2E54D9;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #FFFFFF;
          text-align: center;
          line-height: 40px;
          letter-spacing: 1px;
          margin-left: 95px;
          margin-top: 60px;
        }
      }
    }
    .tab{
      width: 256px;
      height: 56px;
      background: #E6EBF7;
      border-radius: 8px;
      display: flex;
      margin: 44px auto 46px;
      align-items: center;
      justify-content: space-between;
      padding: 0 6px;
      box-sizing: border-box;
      span:nth-of-type(1){
        cursor: pointer;
        width: 110px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        display: block;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 500;
        color: #2E4288;
        &.active{
          box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2100);
          background: #FFFFFF;
        }
      }
      span:nth-of-type(2){
        cursor: pointer;
        width: 124px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        display: block;
        border-radius: 8px;
        font-size: 20px;
        font-weight: 500;
        color: #2E4288;
        &.active{
          box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2100);
          background: #FFFFFF;
        }
      }
    }
  }
  /*@media screen and (max-width: 1600px) {*/
  /*  .index_wrap {*/
  /*    .index_banner {*/
  /*      height: 460px;*/
  /*      .index_banner_title {*/
  /*        !*top: 150px;*!*/
  /*        !*left: 133px;*!*/
  /*      }*/
  /*    }*/

  /*    .learnMore{*/
  /*      margin-top: 110px!important;*/
  /*    }*/
  /*  }*/
  /*}*/

  /*@media screen and (max-width: 1400px) {*/
  /*  .index_wrap {*/
  /*    .index_banner {*/
  /*      height: 602px;*/
  /*      .index_banner_title {*/
  /*        !*top: 210px;*!*/
  /*        !*left: 34px;*!*/
  /*      }*/
  /*    }*/
  /*  }*/
  /*}*/

  /*.arrow-enter {*/
  /*  transform: translateX(-30px);*/
  /*}*/
  /*.arrow-enter-active {*/
  /*  transition: all 0.8s ease;*/
  /*}*/
</style>
<style lang="less">
  .account_dialog {
    width: 600px;
    height: 740px;
    background-color: #ffffff;
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
    border-radius: 5px;
    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
    .info_wrap {
      .info_con {
        padding: 45px 80px 80px;
      }
    }
  }

  .login_dialog {
    width: 683px;
    height: 430px;
    //   height: 500px;
    border-radius: 5px;
    /*padding: 10px 5px!important;*/
    box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);

    .el-dialog__header,
    .el-dialog__body {
      padding: 0;
    }
  }
</style>
